<template>
  <div>
    <v-skeleton-loader v-if="isLoading || loadingSection" type="table"/>

    <div v-if="!loadingSection">
      <v-data-table
        :headers="headers"
        :items="deliveries"
        hide-default-footer
        :items-per-page="15"
        :class="tableClasses"
        v-show="deliveries.length > 0"
      >
        <template #item.id="{ item }">
          <button @click.prevent="openDeliveryModal(item)" class="tw-p-[3px_8px] -tw-mx-[8px] tw-text-[14px] tw-font-semibold tw-text-[#8F3985] tw-rounded-[5px] hover:tw-bg-[#8F3985]/20 tw-duration-300">
            <span v-text="item.id"/>
          </button>
        </template>

        <template #item.arrival_date="{ item }">
          {{ getFormattedDate(item.arrival_date) }}
        </template>

        <template #item.prices="{ item }">
          {{ '$' + item.prices }}
        </template>

        <template #item.status="{ item }">
          <span
            class="tw-p-[4.5px_8px] tw-border tw-rounded-[8px] tw-text-[#0D3659] tw-text-[14px] tw-font-medium"
            :class="[statusClass(item.status_id)]"
            v-text="statusLabel(item.status_id)"
          />
        </template>
      </v-data-table>

      <v-pagination
        v-model="page"
        :length="deliveries_last_page"
        class="custom--v-pagination"
      />
    </div>

    <v-alert v-else color="primary" text class="text-center">Invite your client to the platform</v-alert>
  </div>
</template>

<script>
import loading from '@/mixins/loading';
import { mapActions, mapGetters, mapState } from 'vuex';
import DeliveryCardDetails from '@/components/Modals/DeliveryCardAction.vue';

export default {
  props: {
    branchId: Number | String
  },

  mixins: [loading],

  data () {
    return {
      headers: [
        {
          text: 'Delivery #',
          value: 'id',
          align: 'start',
          sortable: false,
        },
        {
          text: 'Items',
          value: 'item_names',
          sortable: false,
        },
        {
          text: 'Date',
          value: 'arrival_date',
          sortable: false,
        },
        {
          text: 'Customer Name',
          value: 'customer_name',
          sortable: false,
        },
        {
          text: 'Earnings',
          value: 'prices',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false,
          align: 'end',
        },
      ],
      tableClasses: 'tw-mb-[15px] tw-border-[4px] tw-border-solid tw-border-[#FFF] !tw-rounded-[5px] [&_thead_th]:!tw-h-[40px] [&_thead_th]:!tw-text-[14px] [&_thead_th]:!tw-text-[#0D3659] [&_thead_th]:!tw-font-semibold [&_thead_th]:!tw-border-0 [&_tbody_td]:!tw-h-[40px] [&_tbody_td]:tw-text-[14px] [&_tbody_td]:tw-text-[#0D3659] [&_tbody_td]:tw-font-medium [&_tbody_td]:!tw-border-0 [&_tbody_td]:!tw-rounded-none [&_tbody_tr:nth-child(odd)]:tw-bg-[#F5F5F9]',
    };
  },

  methods: {
    ...mapActions('orders', ['loadDeliveries', 'deliveriesGoToPage']),
    ...mapActions('dashboard', ['getDetailedDelivery']),

    async getDeliveries () {
      await this.startLoading(async () => await this.loadDeliveries({ branch_id: this.branchId }));
    },

    getFormattedDate (timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },

    async openDeliveryModal ({ id }) {
      await this.getDetailedDelivery(id);

      this.$modal.show(DeliveryCardDetails, {
        deliveryDetails: this.detailedDelivery,
      }, {
        width: 1200,
        height: 'auto',
      });
    },

    statusClass (status) {
      const statusClasses = [
        'tw-bg-[#FFF7D9] tw-border-[#FFE37E]', // Waiting
        'tw-bg-[] tw-border-[]', // New
        'tw-bg-[#D9FFE3] tw-border-[#94EBAB]', // Accepted
        'tw-bg-[#FBE2FF] tw-border-[#F4ADFF]', // Rejected
        'tw-bg-[#FEE4E2] tw-border-[#FFADAD]', // Cancelled
        'tw-bg-[#E2EAFF] tw-border-[#8EACFC]', // Completed
        'tw-bg-[#FFF7D9] tw-border-[#FFE37E]', // Waiting
        'tw-bg-[#FFF7D9] tw-border-[#FFE37E]', // Waiting for Complete
      ][status];

      return statusClasses;
    },

    statusLabel (status) {
      return [
        'Waiting',
        'New',
        'Accepted',
        'Rejected',
        'Cancelled',
        'Completed',
        'Waiting',
        'Waiting for Complete',
      ][status];
    },
  },

  computed: {
    ...mapGetters('orders', ['deliveries']),
    ...mapState('dashboard', ['detailedDelivery']),
    ...mapState('orders', ['deliveries_last_page', 'loadingSection']),

    page: {
      get () {
        return this.$store.state.orders.deliveries_current_page;
      },

      /**
       * @param {number} val
       * @return {Promise<void>}
       */
      set (val) {
        return this.deliveriesGoToPage({ val, branch_id: this.branchId });
      }
    },
  },

  created () {
    this.getDeliveries();
  },
};
</script>
