<template>
    <v-main class="profile custom--v-main">
        <app-header>
            <h3 class="text-2xl font-semibold text-secondary">
                Profile
            </h3>
            <template #right>
                <v-btn small class="custom--v-btn" color="primary" @click="viewCompany">View as customer</v-btn>
            </template>
        </app-header>

        <section class="profile-tabs price">
            <div class="price__tabs">
                <!-- Tabs -->
                <v-tabs v-model="tab" show-arrows class="pricing-tabs hidden-sm-and-down">
                    <v-tab>Company info</v-tab>
                    <v-tab>Introduction</v-tab>
                    <v-tab>Photos</v-tab>
                    <v-tab>Credentials</v-tab>
                    <v-tab>Payment methods</v-tab>
                </v-tabs>

                <!-- mobile tabs -->

                <v-select 
                    v-model="tab"
                    :items="tabItems"
                    :item-text="text"
                    :item-value="value"
                    append-icon="fa-chevron-down"
                    dense outlined
                    depressed
                    class="hidden-md-and-up custom--v-select"
                 ></v-select>

                <v-tabs-items class="custom--v-tabs-items" v-model="tab" :key="activeBranch.id" v-if="activeBranch">
                    <v-tab-item class="custom--v-tabs-item">
                        <company-info/>
                    </v-tab-item>
                    <v-tab-item class="custom--v-tabs-item">
                        <introduction :tab="tab"/>
                    </v-tab-item>
                    <v-tab-item class="custom--v-tabs-item">
                        <photos/>
                    </v-tab-item>
                    <v-tab-item class="custom--v-tabs-item">
                        <credentials/>
                    </v-tab-item>
                    <v-tab-item class="custom--v-tabs-item">
                        <pricing-payment-methods/>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </section>
    </v-main>
</template>

<script>
import CompanyInfo from "@/views/Profile/CompanyInfo.vue";
import Introduction from "@/views/Profile/Introduction.vue";
import Photos from "@/views/Profile/Photos.vue";
import Credentials from "@/views/Profile/Credentials.vue";
import PricingPaymentMethods from "@/views/Pricing/PricingPaymentMethods.vue";
import { DASHBOARD_URL } from "@/plugins/consts";

export default {
    head() {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: DASHBOARD_URL + '/profile',
                },
            ],
        };
    },

    name: "Profile",

    components: { PricingPaymentMethods, Credentials, Photos, Introduction, CompanyInfo },

    data () {
        return {
            tab: 0,
            tabItems: [
                { text: 'Company info', value: 0 },
                { text: 'Introduction', value: 1 },
                { text: 'Photos', value: 2 },
                { text: 'Credentials', value: 3 },
                { text: 'Payment methods', value: 4}
            ]
        }
    },

    computed: {
        activeBranch () {
            return this.$store.state.auth.activeBranch;
        }
    },

    methods: {
        viewCompany () {
            this.$router.push(
                {
                    name: 'view-company',
                    params: {
                        slug: this.activeBranch.name
                    }
                }
            );
        }
    }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/components/header-tabs";

::v-deep {
    .custom--v-btn {
        border-radius: 6px !important;
        font-weight: 500 !important;
    }
    .custom--v-select {
        max-width: 320px;
        margin-bottom: 20px;


        // small mobile devices
        @media (max-width: 420px) {
            width: 100%;
            max-width: 100%;
            margin:0 auto 20px;
        }

        fieldset {
            border: 1px solid #ddd;
        }

        .v-text-field__details {
            display: none;
        }
        .v-input__slot {
            background: #fff;
            &:focus,
            &:focus-visible,
            &:focus-within {
                border: 1px solid #8F3985 !important;
            }
        }
        
        .v-select__selection--comma {
            font-size: 16px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0.14354728162288666px;
        }

        .v-input__icon i {
            color: #0D3659;
        }
    }
} 
</style>